<template>
    <div class="privacy-statement">
        <section class="bgimage d-flex justify-content-center align-items-center text-white text-center">
            <b-container>
                <b-row>
                    <b-col>
                        <h1>Privacy statement</h1>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="py-5">
            <b-container>
                <p>
                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King,
                    gevestigd aan Groen van Prinstererweg 19-21 2982VA Ridderkerk,
                    is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
                </p>
                <p>
                    Contactgegevens:
                </p>
                <p>
                    https://www.mountainspring-sushiking.nl/ <br/>
                    Groen van Prinstererweg 19-21 2982VA Ridderkerk <br/>
                    0180-418414 <br/>
                </p>
                <p>
                    Hui Wang is de Functionaris Gegevensbescherming van Chinees Specialiteiten
                    Restaurant Mountain Spring | Mountain Spring Sushi King
                    Hij/zij is te bereiken via sushiking2013@gmail.com
                </p>

                <h3 class="mt-5">
                    Persoonsgegevens die wij verwerken
                </h3>
                <p>
                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King
                    verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat
                    u deze zelf aan ons verstrekt.
                    Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
                </p>
                <ul>
                    <li>Voor- en achternaam</li>
                    <li>Adresgegevens</li>
                    <li>Telefoonnummer</li>
                    <li>E-mailadres</li>
                </ul>

                <h3 class="mt-5">
                    Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
                </h3>
                <p>
                    Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger
                    zijn
                    dan 16 jaar.
                    Tenzij ze toestemming hebben van ouders of voogd.
                    We kunnen echter niet controleren of een bezoeker ouder dan 16 is.
                    Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen,
                    om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming.
                    Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een
                    minderjarige,
                    neem dan contact met ons op via sushiking2013@gmail.com, dan verwijderen wij deze informatie.
                </p>

                <h3 class="mt-5">
                    Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
                </h3>
                <p>
                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King
                    verwerkt uw persoonsgegevens voor de volgende doelen:
                </p>
                <ul>
                    <li>U te informeren over wijzigingen van onze diensten en producten</li>
                    <li>Om goederen en diensten bij u af te leveren</li>
                </ul>

                <h3 class="mt-5">
                    Geautomatiseerde besluitvorming
                </h3>
                <p>
                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King neemt niet
                    op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke)
                    gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's
                    of
                    -systemen,
                    zonder dat daar een mens (bijvoorbeeld een medewerker van Chinees Specialiteiten
                    Restaurant Mountain Spring | Mountain Spring Sushi King) tussen zit.
                </p>

                <h3 class="mt-5">
                    Hoe lang we persoonsgegevens bewaren
                </h3>
                <p>
                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King bewaart uw persoonsgegevens
                    niet
                    langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld.
                    Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:
                </p>
                <ul>
                    <li>Personalia geen bewaringstermijn, zodat we weten naar wie we de bestelling hebben verzonden</li>
                    <li>Adres geen bewaringstermijn, zodat we weten naar welke locatie we de bestelling hebben verzonden</li>
                </ul>

                <h3 class="mt-5">
                    Delen van persoonsgegevens met derden
                </h3>
                <p>
                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring
                    Sushi King verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze
                    overeenkomst
                    met u of om te voldoen aan een wettelijke
                    verplichting.
                </p>

                <h3 class="mt-5">
                    Cookies, of vergelijkbare technieken, die wij gebruiken
                </h3>
                <p>
                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King gebruikt geen cookies of
                    vergelijkbare technieken.
                </p>

                <h3 class="mt-5">
                    Gegevens inzien, aanpassen of verwijderen
                </h3>
                <p>
                    U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het
                    recht om
                    uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking
                    van uw
                    persoonsgegevens door Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King en
                    heeft u het
                    recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de
                    persoonsgegevens die
                    wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen.

                    U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of
                    verzoek tot
                    intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar
                    sushiking2013@gmail.com.

                    Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw
                    identiteitsbewijs
                    met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met
                    nummers
                    onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw
                    privacy. We
                    reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek.

                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King wil u er tevens op wijzen dat
                    u de
                    mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit
                    Persoonsgegevens. Dat
                    kan via de volgende link:
                    https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
                </p>

                <h3 class="mt-5">Hoe wij persoonsgegevens beveiligen</h3>
                <p>
                    Chinees Specialiteiten Restaurant Mountain Spring | Mountain Spring Sushi King neemt de bescherming van uw
                    gegevens
                    serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en
                    ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er
                    aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via sushiking2013@gmail.com
                </p>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: "PrivacyStatement"
    }
</script>

<style scoped>
    .privacy-statement .bgimage {
        background: url('../assets/header/banner-privacy.jpg');
        background-size: cover;
    }
</style>